const initialState = {
    data: [],
    total: 1,
    abnormal_total: -1,
    params: {},
    modal: {
        item: {},
        title: '',
        type: '',
        status: false
    },
    message: '',
    status: '',
    data_success: null
}

const transactionCreditList = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSACTION_CREDIT_ABNORMAL':
            return {
                ...state,
                abnormal_total: action.data
            }
        case 'TRANSACTION_CREDIT_CLEAR':
            return {
                ...state,
                abnormal_total: -1
            }
        default:
            return { ...state }
    }
}

export {
    transactionCreditList
}
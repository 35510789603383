const initialState = {
  modal_customer_transaction_game: {
    data: [],
    total: -1
  },
  modal_customer_transaction_game_winloss: {
    data: [],
    total: -1
  },
  modal_customer_transaction_game_ticket: {
    data: [],
    total: -1
  },
  modal_customer_transaction_game_wheel: {
    data: [],
    total: -1
  },
  modal_customer_activity: {
    data: [],
    total: -1
  },
  modal_customer_transaction_credit: {
    data: [],
    total: -1
  },
  modal_customer_invite_friend: {
    data: [],
    total: -1
  },
  modal_customer_promotion: {
    data: [],
    total: -1
  },
  modal_customer_shop: {
    data: [],
    total: -1
  },
  modal_customer_wallets: {
    data: [],
    total: -1
  },
  modal_customer_banks: {
    data: [],
    total: -1,
    log: []
  },
  organization_sub_history: {
    data: [],
    total: -1,
    log: []
  },
  //payment
  bank_bot_list: {
    data: [],
    total: -1,
    params: {}
  },
  deposit_list: {
    data: [],
    total: -1,
    params: {}
  },
  withdraw_list: {
    data: [],
    total: -1,
    params: {}
  },
  organization_agentbank_list: {
    data: [],
    total: -1,
    params: {}
  },
  organization_agentbank_transaction_list: {
    data: [],
    total: -1,
    params: {}
  },
  organization_agentbank_channel_list: {
    data: [],
    total: -1,
    params: {}
  },
  
  //customer
  customer_list: {
    data: [],
    total: -1,
    params: {}
  },
  organization_sub_list: {
    data: [],
    total: -1,
    params: {}
  },
  //report
  win_loss_list: {
    data: [],
    total: -1,
    params: {}
  },
  win_loss_game_list: {
    data: [],
    total: -1,
    params: {}
  },
  win_loss_customer_list: {
    data: [],
    total: -1,
    params: {}
  },
  commission_list: {
    data: [],
    total: -1,
    params: {}
  },
  commission_category_list: {
    data: [],
    total: -1,
    params: {}
  },
  cashback_list: {
    data: [],
    total: -1,
    params: {}
  },
  cashback_category_list: {
    data: [],
    total: -1,
    params: {}
  },
  game_cmap_list: {
    data: [],
    total: -1,
    params: {}
  },
  deposit_again_list: {
    data: [],
    total: -1,
    params: {}
  },
  customer_referral_summary: {
    data: [],
    total: -1,
    params: {}
  },
  customer_disappear: {
    data: [],
    total: -1,
    params: {}
  },
  organization_agent_credit_transaction: {
    data: [],
    total: -1,
    params: {}
  },
  marketing_report_referral: {
    data: [],
    total: -1,
    params: {}
  },
  marketing_report_summary_referral: {
    data: [],
    total: -1,
    params: {}
  },
  report_activity_apply: {
    data: [],
    total: -1,
    params: {}
  },
  //setting
  setting_role_list: {
    data: [],
    total: -1,
    params: {}
  },
  game_list: {
    data: [],
    total: -1,
    params: {}
  },
  image_game_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_event_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_hot_list: {
    data: [],
    total: -1,
    params: {}
  },
  setting_contact_list: {
    data: [],
    total: -1,
    params: {}
  },
  setting_agent_list: {
    data: [],
    total: -1,
    params: {}
  },
  setting_agent_cms_list: {
    data: [],
    total: -1,
    params: {}
  },
  mission_wheel_list: {
    data: [],
    total: -1,
    params: {}
  },
  event_activity_list: {
    data: [],
    total: -1,
    params: {}
  },
  event_activity_choose_list: {
    data: [],
    total: -1,
    params: {}
  },
  event_activity_reward_list: {
    data: [],
    total: -1,
    params: {}
  },
  setting_category_withdraw_list: {
    data: [],
    total: -1,
    params: {}
  },
  
  //promotion
  promotion_apply_list: {
    data: [],
    total: -1,
    params: {}
  },
  //lottery
  lottery_ticket: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_ticket_number: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_round_result: {
    data: [],
    total: -1,
    params: {}
  },
  //marketing
  transfer_customer: {
    data: [],
    total: -1,
    params: {}
  },
  customer_referral_summary: {
    data: [],
    total: -1,
    params: {}
  },
  marketing_referral_customer_list: {
    data: [],
    total: -1,
    params: {}
  },
  marketing_referral_member_list: {
    data: [],
    total: -1,
    params: {}
  },
  transaction_analyze_list: {
    data: [],
    total: -1,
    params: {}
  },
  transaction_credit_list: {
    data: [],
    total: -1,
    params: {}
  }
}

export default initialState
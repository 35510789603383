const initialState = {
    data: [],
    dataSelect: null,
    summarize: null,
    total: 1,
    params: {},
    modal: {
        item: {},
        title: '',
        type: '',
        status: false
    },
    message: '',
    status: '',
    data_success: null
}

const transactionAnalyzeList = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSACTION_ANALYZE_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'TRANSACTION_ANALYZE_OPEN_MODAL':
            return {
                ...state,
                open: action.open_modal,
                dataSelect: action.data
            }
        case 'TRANSACTION_ANALYZE_GET_SUMMARIZE':
            return {
                ...state,
                summarize: action.data
            }
        case 'TRANSACTION_ANALYZE_VERIFY':
            return {
                ...state,
                data_success: action.status === "OK"
            }
        case 'TRANSACTION_ANALYZE_CLEAR':
            return {
                ...state,
                data_success: false
            }
        default:
            return { ...state }
    }
}

const transactionSuspicious = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSACTION_SUSPICIOUS_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        case 'TRANSACTION_SUSPICIOUS_APPROVE_STATUS':
            return {
                ...state,
                data_success: action.status === "OK"
            }
        case 'TRANSACTION_SUSPICIOUS_GET_SUMMARY':
            return {
                ...state,
                summarize: action.data
            }
        case 'TRANSACTION_SUSPICIOUS_CLEAR_STATE':
            return {
                ...state,
                data_success: false
            }
        default:
            return { ...state }
    }
}

const logTransactionSuspicious = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_TRANSACTION_SUSPICIOUS_GET_DATA':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
    }
}

export {
    transactionAnalyzeList,
    transactionSuspicious,
    logTransactionSuspicious
}